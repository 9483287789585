@font-face {
  font-family: MyFont;
  src: url('https://adammenges.com/font-backup/Kanit-Thin.ttf');
}

* {
  scroll-behavior: smooth;
}

html {
  background-color: #020100;
  /* background-color: #ededed; */
}

body {
  font-family: MyFont, sans-serif;
  /* font-family: 'SF Pro Display', sans-serif; */
  font-weight: 300;
  font-size: 16px;
  color: rgb(194, 194, 194);
  margin: 0;
  padding: 0;
}

.a-black {
  font-family: MyFont, sans-serif;
  /* font-family: 'SF Pro Display', sans-serif; */
  color: rgb(194, 194, 194);
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
}

.a-white {
  font-family: MyFont, sans-serif;
  /* font-family: 'SF Pro Display', sans-serif; */
  color: rgb(37, 37, 37);
  text-decoration: none;
  font-weight: 300;
  font-size: 16px;
}

#headerBackground {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background-color: #020100;
}

#footerBackground {
  position: fixed;
  bottom: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background-color: #020100;
}

.me-blend {
  /* background-image: url('/me.jpg'); */
  background-image: url('https://adammenges.com/me.jpg');
  background-size: 700px 700px;
  width: 700px;
  height: 700px;
  background-color: #020100;
  background-repeat: no-repeat;
  background-blend-mode: screen;
}

.me-blend-white {
  /* background-image: url('/me-white-circle.jpg'); */
  background-size: 300px 300px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: #020100;
  background-repeat: no-repeat;
  background-blend-mode: screen;
}

.me-blend-small {
  /* background-image: url('/me.jpg'); */
  background-image: url('https://adammenges.com/me.jpg');
  background-size: 450px 450px;
  width: 450px;
  height: 450px;
  background-color: #020100;
  background-repeat: no-repeat;
  background-blend-mode: screen;
}

.me-blend-small-white {
  /* background-image: url('/me-white-circle.jpg'); */
  background-size: 250px 250px;
  width: 250px;
  height: 250px;
  background-color: #020100;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-blend-mode: screen;
}

.circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  /* padding-top: 50%; */
}

.rectangle {
  /* border-radius: 10%; */
  width: 30px;
  height: 4px;
  /* padding-top: 50%; */
}

.noWebShit {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  cursor: default;
}
